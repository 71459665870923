.ambassador-wrapper {
  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
    color: #494949;
  }
  .modal-details {
    display: flex;
    .modal-name {
      margin-right: 24px;
    }
  }
  .ambassador-info {
    font-size: 16px;
    color: #676767;
  }
  .ambassador-details {
    font-size: 16px;
    color: #494949;
  }
  .verify-ambassador {
    margin-top: 8px;
    button {
      background: #e33434;
      border-radius: 4px;
      font-size: 16px;
      color: #f9f9f9;
      padding: 4px 8px;
      border: none;
      cursor: pointer;
    }
  }
  .ambassador-more {
    background: #ffffff;
    border: 1px solid #b1acac;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1.5px solid #494949;
    box-sizing: border-box;
    border-radius: 2px;
    font-size: 14px;
    color: #4a4a4a;
    padding-left: 8px;
    outline: none;
    cursor: pointer;
    img {
      position: relative;
      top: -1px;
      left: -2px;
    }
  }
  .ambassador-status {
    border-radius: 4px;
    font-size: 16px;
    color: #494949;
    width: fit-content;
    
  }
  .ambassador-verified {
    padding: 3px 8px;
    background: #92ce8c;
  }
}
