.signBg {
    width: 100% !important;
    height: auto;
    min-height: 100vh;
    background: url('../assets/images/signInBg.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment:fixed;
    padding-bottom: 10px;
}
.form{
    width:20%;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 1;
  
    @media screen and (max-width:2000px){
      width: 25%;
    }
  
  
    @media screen and (max-width:1501px){
      width: 30%;
    }
  
    @media screen and (max-width:1024px){
      width: 45%;
    }
  
    @media screen and (max-width:700px){
      width: 90%;
      top: 60%;
      position: sticky;
      transform: translate(-5%,0%);
    }
  }

.card-signin {
    background-color: rgba(0, 0, 0, 0.842);
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
    text-align: center;
    i{
        font-size: 50px;
        margin-bottom: 5%;

    }
}

.form-signin .btn, .btn-google, .btn-facebook {
    font-size: 80%;
    border-radius: 5rem;
    letter-spacing: .1rem;
    font-weight: bold;
    padding: 1rem;
    transition: all 0.2s;
}

.form-label-group {
    input {
        height: auto;
        border-radius: 2rem;
    }

    >input,
    >label {
        padding: .65rem 1.5rem;
    }
}

.btn-google {
    color: white;
    background-color: #ea4335;

    &:hover {
        background-color: #d43224;
        color: white;
    }
}

.btn-facebook {
    color: white;
    background-color: #3b5998;

    &:hover {
        background-color: #2f4a85;
        color: white;
    }
}