$primary: #E50914;

$btn-border-radius: 2px;
$btn-border-radius-lg: 2px;
$btn-border-radius-sm: 2px;

$font-bg: 20px;
$font-xl: 30px;

// $enable-gradients: true;

// Dashboard Vars
$navbar-base-height: 56px;
$sidebar-base-width: 200px;
$sidebar-collapsed-width: 90px;
$sticky-footer-height: 50px;
