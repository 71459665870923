html,
body {
  @import url("https://fonts.googleapis.com/css?family=Sarala&display=swap");
  font-family: "Sarala", sans-serif !important;
}

body {
  background: rgb(0, 0, 0) !important;
  color: white !important;
}

.bg{
  position: absolute;
  top: 0;
  z-index: 0;
  width: 100%;
  height: 100vh;
  background: radial-gradient( rgba(134, 200, 245, 0.1),rgba(0, 0, 0, 0.9));
  background-color: rgba(0, 0, 0, 0.5);
  @media screen and (max-width:1000px) {
    background: radial-gradient( rgba(255, 255, 255, 0.1),rgba(5, 0, 0, 0.9));
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.text-lg {
  font-size: $font-bg;
}

.text-xl {
  font-size: $font-bg;
  line-height: 1.2;

  @include media-breakpoint-up(lg) {
    font-size: $font-xl;
  }
}

.nohover:hover {
  background: transparent !important;
  color: white !important;
}

.animation-container {
  position: relative;
  min-height: 300px;

  @include media-breakpoint-up(lg) {
    min-height: 400px;
  }
}

.navbar-dark {
  transition: background-color 200ms linear;
}

.hSection {
  min-height: 90vh !important;
}

.hFullSection {
  min-height: 100vh !important;
}

@media screen and (min-width: 1400px) {
  .nextGrid {
    max-width: 400px;
  }
}

.icon {
  position: absolute;
  width: 100%;
  margin-top: 20%;
  display: block;
  color: white;
  font-size: 1.2em;
  -webkit-transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  -moz-transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  &:hover {
    color: $primary;
  }
}

#circ {
  max-width: 100%;
  width: 500px;
  margin: 0 auto;
}
#circ ul {
  list-style: none;
}
#circ ul li {
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  width: 45px;
  height: 45px;
  margin: 0 15px 15px 15px;
  text-align: center;
  background: black;
  border: solid 2px $primary;
  position: relative;
  transition: all 0.4s;
}
#circ ul li:hover {
  border: solid 2px white;
  transition: all 0.4s;
  cursor: pointer;
}

@media only screen and (max-width: 1200px) {
  .icon {
    position: relative;
    margin-top: 10%;
    color: $primary;
    font-size: 1.3em;
    -webkit-transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    -moz-transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  #circ ul li {
    width: 70px;
    height: 70px;
    margin: 0 5px 5px 5px;
  }
  #circ ul li .icon {
    font-size: 1.5em;
    color: white;
  }
  #circ ul li:hover .icon {
    color: $primary;
  }
}
@media only screen and (max-width: 690px) {
  #circ ul li {
    border-radius: 0;
    border: none;
    margin: 0;
  }
  #circ ul li:hover {
    border: none;
  }
}
@keyframes spin{
  0%{
    transform: rotate(0deg);
  }

  100%{
    transform: rotate(360deg);
  }
}

.refresh{
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e9ecef;
  border-radius: 0.25rem;
  padding: 0.55rem 1rem;
  i{
    color:#888;
  }
}

.content-header{
  display: flex;
  .input-field{
    background: #e9ecef;
    padding: 0.55rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 1%;
    width: 15%;
    min-width: 360px;
    border-radius: 0.25rem;
    input{
      background: transparent;
      border: none;
      outline: none;
      flex: .95;
    }
    i{
      color:#888;
    }
    .fa-times{
      cursor: pointer;
      transition: all .5s;
      &:hover{
        color: black;
      }
    }
  }
}