.restrictions{
    width:100%;
    @media screen and(max-width:700px) {
        h5{ font-size: 20px; }
    }
    section{
        margin-left:0 ;
        display: inline;
        div{
            align-items: center;
            margin: 1% 0;
            overflow: auto;

            @media screen and(max-width:700px) {
                flex-direction: column;
                margin: 1% 0;
            }
            
            .continents{
                display: flex;
                margin: 0 1%;
                padding: 10px;
                border: 1px solid lightgray;
                border-radius: 2px;

                @media screen and(max-width:700px) {
                    display: grid;
                    margin:0;
                    grid-gap: 5px;
                }

                .cont{
                    margin: 0 10px;
                    display: flex;
                    color: whitesmoke;
                    position: relative;

                    span{
                        padding: 5px;
                        background-color: #E50914;
                        cursor: pointer;
                        border-left: 1px  solid lightgrey;
                        display: flex;
                        align-items: center;

                        i{ font-size: smaller; }

                        strong{
                            position: absolute;
                            top: -100%;
                            background-color: rgb(224, 224, 224);
                            width: 130%;
                            color: #333;
                            font-weight: lighter;
                            font-size: 12px;
                            border: 1px solid #333;
                            border-radius: 5px;
                            padding: 0 5px;
                            text-align: left;
                            z-index: 1;
                            display: none;
                        }

                        &:first-child{
                            border-top-left-radius: 5px;
                            border-bottom-left-radius: 5px;
                            font-size: 15px;
                        
                            &:hover{
                                background-color: #b4060f;
                                strong{
                                    display: initial;
                                }
                            }
                        }

                        &:last-child{
                            padding: 5px 10px;
                            border-top-right-radius: 5px;
                            border-bottom-right-radius: 5px;
                        
                            &:hover{
                                background-color: #b4060f;
                                strong{
                                    display: initial;
                                }
                            }
                        }
                    }
                }
            }
        }
        .countries{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 1%;
            align-items: initial;

            @media screen and(max-width:700px) {
                grid-template-columns: 1fr ;
            }

            .specific{
                align-items: initial;

                @media screen and(max-width:700px) {
                    height: 30vh;
                }
    
                .second{
                    display:grid;
                    grid-gap: 5px;
                    overflow-y: scroll;
                    height: 300px;
                    padding: 10px;
                    background: whitesmoke;
                    section{  
                        span { 
                            color: #333;
                            background: lightgrey;
                            &:last-child{
                                display: flex;
                                justify-content: center;
                                font-weight: bold;
                                border-left: 1px solid #333;
                                &:hover{
                                    background: #e9ecef;
                                    color: #E50914;
                                }
                            } 
                        }   
                    }
                }
            }

        }
        
    }
}
.custom-file-label{
    @media screen and (max-width:700px){
        font-size: 10px;
    }
}