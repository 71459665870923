.hold{
    position: relative;
    .progress{
        background: url('../../assets/images/tape.png') no-repeat;
        background-size: cover;
        background-color: #333;
        background-blend-mode: darken;
        width: 60%;
        height: 50%;
        z-index: 1;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 5px 30px 0px #333;
        @media screen and (max-width:700px) {
            width: 100%;
            height: 100%;
        }
        .indicator{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%) scale(1.5);
            color: white;
        }
        h3{
            position: absolute;
            top: 5%;
            left: 5%;
            color: whitesmoke;
            font-size: 30px;
            text-transform: uppercase;
            text-shadow: 1px 1px 5px grey;
        }
    }
    .special{
        left:90%;
        height: 80%;
        width: 80%;
    }
}

.trailer-handler{
    background: #333;
    padding: 10px;
    color: grey;
    border-radius: 10px;
    div{
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    h5{
        color: whitesmoke;
    }
    input{
        outline: none;
    }
    button{
        color: white;
        padding: 5px 10px;
        border: none;
        border-radius: 5px;
        background: #E50914;
        outline: none;
        &:hover{
            background: #ee2933;
        }
        i{
            margin-left: 5px;
        }
    }
}
