html {
  position  : relative;
  min-height: 100%;
}

.whiteBg {
  width        : 100%;
  height       : auto;
  // min-height: 100vh;
  background   : white !important;
}

.blackBg {
  background: rgb(0, 0, 0);
}

.darkBg {
  background: rgb(27, 27, 27);
}

.transBg {
  background: rgba(255, 0, 0, 0.15);
}

.text-link {
  color: #0062CC;

  &:hover {
    color: #0062CC;
  }
}

.card-img {
  max-height: 200px;
}


.grid-divider {
  overflow-x: hidden; //quickfix to hide divider on left side
  position  : relative;
}

.grid-divider>[class*="divider"]:nth-child(n + 2):after {
  content         : "";
  background-color: rgb(34, 34, 34);
  position        : absolute;
  top             : 0;
  bottom          : 0;

  @media (max-width: 767px) {
    width : 100%;
    height: 1px;
    left  : 0;
    top   : -4px; // quickfix to center line between columns
  }

  @media (min-width: 768px) {
    width : 1px;
    height: auto;
    left  : -1px; // quickfix to hide divider on left side
  }
}

.vcenter {
  display    : flex;
  align-items: center;
}

@media screen and (min-width: 1900px) {
  .container {
    width: 1870px !important;
  }
}
