#license {
    position: absolute;
    right: 3px;
}
#license span {
    color:#f7f7f7;
    font-size: 12px;
    margin-right: 1px;
}

#redmark {
    height: 30px;
    @media (min-width: 700px) and (max-width: 1100px) {
        height: 26px;
      }
}
.dcard {
    position: relative;
}